import {
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
  TextFieldProps,
  createFilterOptions,
} from "@mui/material"
import { clsx } from "clsx"
import { useField } from "formik"
import { ReactElement, useEffect, useState } from "react"

export type CreatableAutocompleteOption = { id: string; label: string; inputValue?: string; searchableString?: string }

type Props = {
  className?: string
  clear?: () => void
  defaultValue?: string
  disabled?: boolean
  disableCreate?: boolean
  errorPlaceHolder?: ReactElement
  fullWidth?: boolean
  label: string
  onCreate?: (inputValue: string) => void
  options: CreatableAutocompleteOption[]
  optionsToDisable?: Record<string, boolean>
  placeholder?: string
  required?: boolean
  variant?: TextFieldProps["variant"]
}

export function CreatableAutocomplete(props: Props & { name: string }) {
  const [, { touched, error }, { setValue }] = useField(props.name)
  const displayError = touched && error && !props.disabled
  return <CreatableAutocompleteRoot {...props} {...{ setValue, displayError, error }} />
}

function CreatableAutocompleteRoot({
  className,
  defaultValue,
  disabled,
  disableCreate,
  displayError,
  error,
  errorPlaceHolder = <>&nbsp;</>,
  fullWidth,
  label,
  onCreate,
  options,
  optionsToDisable,
  placeholder,
  required,
  setValue,
  variant,
}: Props & {
  displayError?: boolean | ""
  error?: string
  setValue: (value: string | null) => void
  value?: CreatableAutocompleteOption | null
  optionsToDisable?: Record<string, boolean>
}) {
  const [selected, setSelected] = useState<CreatableAutocompleteOption | null>(null)

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find((option) => option.id === defaultValue)
      if (defaultOption) {
        setValue(defaultValue)
        setSelected(defaultOption)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length])

  return (
    <MuiAutocomplete
      autoHighlight
      className={className}
      fullWidth={fullWidth}
      value={selected}
      disabled={disabled}
      onChange={(_event, newValue: CreatableAutocompleteOption | string | null) => {
        if (newValue === null) {
          setValue(null)
          setSelected(null)
        } else if (typeof newValue === "string") {
          setValue(newValue)
          setSelected({ id: newValue, label: newValue })
        } else if (newValue?.inputValue) {
          setSelected({ id: newValue.inputValue, label: newValue.inputValue })
          if (onCreate) onCreate(newValue.inputValue)
        } else {
          setValue(newValue.id)
          setSelected(newValue)
        }
      }}
      filterOptions={(filterOptions, params) => {
        const filtered = createFilterOptions<CreatableAutocompleteOption | string>({
          stringify: (option) => (typeof option === "string" ? option : option.searchableString || option.label),
        })(filterOptions, params)

        const { inputValue } = params

        const optionDoesNotExist = filterOptions.some((option) =>
          typeof option === "string" ? inputValue !== option : inputValue !== option.label
        )

        if ((inputValue !== "" && optionDoesNotExist) || filterOptions.length === 0) {
          filtered.push({ id: inputValue, inputValue, label: inputValue })
        }

        return filtered
      }}
      options={options}
      getOptionDisabled={(option) => {
        return (typeof option !== "string" && optionsToDisable?.[option.id]) || false
      }}
      renderOption={(props, option) => {
        if (typeof option === "string") return <li {...props}>{option}</li>
        if (option.inputValue && disableCreate) return null
        if (option.inputValue)
          return <li {...props} className={clsx(props.className, "text-blue-600")}>{`Add "${option.inputValue}"`}</li>
        return <li {...props}>{option.label}</li>
      }}
      freeSolo
      forcePopupIcon
      renderInput={(params) => (
        <MuiTextField
          {...params}
          variant={variant}
          size="small"
          required={required}
          label={label}
          helperText={displayError ? error : errorPlaceHolder}
          error={!!displayError}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            style: { boxShadow: "none" },
          }}
          InputProps={{
            ...params.InputProps,
            ...(variant === "standard" ? { disableUnderline: true } : {}),
          }}
          FormHelperTextProps={{ className: "my-0.5" }}
        />
      )}
    />
  )
}
