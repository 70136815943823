import { Typography } from "@mui/material"
import { DateRange, DateRangePicker, DateRangePickerProps, SingleInputDateRangeField } from "@mui/x-date-pickers-pro"

import { useField } from "formik"
import React from "react"

interface DateRangePickerWrapperProps extends DateRangePickerProps<DateRange<[Date | null, Date | null]>> {
  name: string
}

export const FormikDateRangePicker: React.FC<DateRangePickerWrapperProps> = ({ name, ...rest }) => {
  const [{ value }, { error }, { setValue }] = useField(name)
  return (
    <>
      <DateRangePicker
        sx={{
          width: "100%",
        }}
        {...rest}
        slots={{ field: SingleInputDateRangeField }}
        onChange={(val) => setValue(val)}
        defaultValue={value || null}
      />
      {error && <Typography className="text-red-600">Start and end date is required</Typography>}
    </>
  )
}
