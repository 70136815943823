import { Autocomplete, TextField, Tooltip, Typography } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"
import { addMinutes, isAfter } from "date-fns"
import { useField, useFormikContext } from "formik"
import { FC, useEffect, useState } from "react"
import { BiHelpCircle } from "react-icons/bi"
import { ScheduleWorkDay, ScheduledBreak } from "../../../graphql/generated/client-types-and-hooks"
import {
  NonWorkDays,
  WorkHours,
} from "../../../components/Partials/Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"

type FormValues = {
  isDefault: boolean
  workDays: ScheduleWorkDay[]
  workHours: WorkHours
  nonWorkDays: NonWorkDays
  workDayBreaks: ScheduledBreak[]
}

const getActiveBreakMinutes = (workDayBreaks: ScheduledBreak[], startTime: Date) => {
  const breaksAfterStartTime = getBreaksAfterStartTime(startTime, workDayBreaks)

  return (
    breaksAfterStartTime?.reduce(
      (total, scheduleBreak) => (scheduleBreak.isActive ? (total += scheduleBreak.durationInMinutes || 0) : total),
      0
    ) || 0
  )
}

const getBreaksAfterStartTime = (startTime: Date, workDayBreaks: ScheduledBreak[]) => {
  return workDayBreaks.filter((scheduledBreak) => {
    const [scheduledBreakHours, scheduledBreakMinutes] = scheduledBreak.localizedStartTime.split(":")
    const localizedBreakDateTime = new Date(new Date().setHours(+scheduledBreakHours, +scheduledBreakMinutes))

    return isAfter(localizedBreakDateTime, startTime)
  })
}

const getNewEndTime = (startTime: Date, hours: number, workDayBreaks: ScheduledBreak[]) => {
  const activeBreakMinutes = getActiveBreakMinutes(workDayBreaks, startTime)
  const newEndTime = addMinutes(startTime, hours * 60 + activeBreakMinutes)

  return newEndTime
}

export const WorkDayHours: FC<{ name: string }> = ({ name }) => {
  const { values } = useFormikContext<FormValues>()
  const [{ value }, errors, { setValue }] = useField(name)

  const [hours, setHours] = useState<number | null>(value ? value.hours : null)
  const hoursOptions = Array.from({ length: 24 }, (_, i) => i + 1) // [1, 2, 3, ... 24]
  const [startTime, setStartTime] = useState<Date | null>(value?.startTime)
  const [endTime, setEndTime] = useState<Date | null>(value?.endTime)

  useEffect(() => {
    setValue({ hours, startTime, endTime })
  }, [hours, startTime, endTime, setValue])

  useEffect(() => {
    if (!startTime && !hours) {
      return
    }

    const newEndTime = getNewEndTime(startTime!, hours!, values.workDayBreaks)

    setEndTime(newEndTime)
  }, [values?.workDayBreaks, startTime, hours])

  const handleHoursChange = (_e: React.SyntheticEvent, hoursChangeValue: number | null) => {
    if (hoursChangeValue && startTime) {
      const newEndTime = getNewEndTime(startTime, hoursChangeValue, values.workDayBreaks)
      setEndTime(newEndTime)
    }

    if (!hoursChangeValue) {
      setEndTime(null)
    }

    setHours(hoursChangeValue)
  }

  const handleStartTimeChange = (date: Date | null) => {
    if (hours && date) {
      // update endTime
      const newEndTime = getNewEndTime(date, hours, values.workDayBreaks)
      setEndTime(newEndTime)
    }

    setStartTime(date)
  }

  const handleEndTimeChange = (date: Date | null) => {
    if (date && startTime) {
      const hoursDiff = date.getHours() - startTime.getHours()

      if (hoursDiff < 0) {
        return
      }

      const activeBreakMinutes = getActiveBreakMinutes(values.workDayBreaks, startTime)

      const totalMinutes = hoursDiff * 60 + (date.getMinutes() - startTime.getMinutes()) - activeBreakMinutes
      const totalHours = Math.floor(totalMinutes / 60)

      setHours(totalHours)
    }

    setEndTime(date)
  }

  return (
    <div>
      <div className="flex gap-x-2 items-center">
        <Typography variant="h5" fontSize={16}>
          Workday Hours
        </Typography>
        <Tooltip arrow title="Working hours for this schedule">
          <div>
            <BiHelpCircle className="text-gray-400 relative top-[-7px]" />
          </div>
        </Tooltip>
      </div>

      <div className="flex gap-x-2">
        <Autocomplete
          getOptionLabel={(option) => option.toString()}
          value={hours}
          onChange={handleHoursChange}
          options={hoursOptions}
          renderInput={(params) => (
            <TextField {...params} label="Working Hours" error={errors.touched && !!errors.error} />
          )}
          sx={{ width: 300 }}
        />
        <TimePicker label="Start time" value={startTime} onChange={handleStartTimeChange} />
        <TimePicker label="End time" value={endTime} onChange={handleEndTimeChange} />
      </div>
    </div>
  )
}
