import { IconButton, Modal, Typography } from "@mui/material"
import { clsx } from "clsx"
import { FC, ReactNode, createContext, useState } from "react"
import { BiX } from "react-icons/bi"
import { Optionize } from "../../types/helpers"
import { LoadingContainer } from "../Loading/LoadingContainer"
import { ModalProps } from "../Modals/hooks/useModalProps"

type Props = {
  children: ReactNode
  className?: string
} & Optionize<ModalProps, "handleOpenModal">

type ContextProps = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

type ModalTitleProps = {
  children: ReactNode
}

export const ModalLoadingContext = createContext<ContextProps>({} as ContextProps)

export const ModalTitle: FC<ModalTitleProps> = ({ children }) => {
  return <span className="truncate">{children}</span>
}
/**
 * @deprecated use `MuiModal` instead
 */
const DeprecatedModal: FC<Props> = ({
  children,
  className = "",
  contentLabel,
  handleOpenModal: _handleOpenModal,
  handleCloseModal,
  isOpen,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Modal open={isOpen} onClose={isLoading ? () => {} : handleCloseModal} disableRestoreFocus {...props}>
      <div
        className={clsx(
          "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-t-xl rounded-b-2xl bg-white size-full md:rounded-xl md:mt-0 md:h-auto md:max-h-[660px] md:max-w-[488px]",
          className
        )}
      >
        <ModalLoadingContext.Provider value={{ isLoading, setIsLoading }}>
          <LoadingContainer isLoading={isLoading} className="h-full flex flex-col">
            <div
              className={clsx(
                "rounded-t-xl flex items-center gap-x-2.5 justify-start border-b px-4 py-3.5 leading-none",
                "md:flex-row-reverse md:pl-8 md:pr-5 md:py-5"
              )}
            >
              <IconButton onClick={handleCloseModal}>
                <BiX className="size-6" />
              </IconButton>
              <div className="w-full flex items-center justify-between text-xl md:text-2xl font-medium truncate">
                {typeof contentLabel === "string" ? (
                  <Typography variant="h3" className="truncate">
                    {contentLabel}
                  </Typography>
                ) : (
                  contentLabel
                )}
              </div>
            </div>
            {children}
          </LoadingContainer>
        </ModalLoadingContext.Provider>
      </div>
    </Modal>
  )
}
export default DeprecatedModal
