import { Chip, Typography } from "@mui/material"
import { addMinutes, format, isEqual, startOfDay } from "date-fns"
import { FC, ReactNode } from "react"
import { ScheduleBreak } from "./ScheduleDetails"

export const getFormattedTime = (time: string | Date | null | undefined) => {
  if (!time) {
    return "None"
  }

  if (typeof time !== "string") {
    time = format(time, "hh:mm")
  }

  const [hours, minutes] = time.split(":")
  const date = new Date(new Date().setHours(+hours, +minutes))

  return format(date, "h:mm a")
}

export const getNonWorkDayLabel = (nonWorkDay: { name: string; dateRange: Date[]; active: boolean }) => {
  const [start, end] = nonWorkDay.dateRange

  if (isEqual(startOfDay(start), startOfDay(end))) {
    return (
      <div className="flex gap-x-2">
        <Typography>{nonWorkDay.name}</Typography>
        <Typography className="text-slate-500">{format(start, "MM/dd/yyyy")}</Typography>
      </div>
    )
  }

  return (
    <div className="flex gap-x-2">
      <Typography>{nonWorkDay.name}</Typography>
      <Typography  className="text-slate-500">{`${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`}</Typography>
    </div>
  )
}

export const getBreakLabel = (scheduledBreak: ScheduleBreak) => {
  const [hours, minutes] = scheduledBreak.localizedStartTime.split(":")

  const startDate = new Date(new Date().setHours(+hours, +minutes))
  const startTime = format(startDate, "h:mm a")
  const endDate = addMinutes(startDate, scheduledBreak.durationInMinutes)
  const endTime = format(endDate, "h:mm a")

  const paidOrUnpaid = scheduledBreak.breakTask?.isUnpaid ? "Unpaid" : "Paid"

  return (
    <div className="flex gap-x-2">
      <Typography>{scheduledBreak.name}</Typography>
      <Typography  className="text-slate-500" >{`${startTime} – ${endTime} / ${paidOrUnpaid}`}</Typography>
    </div>
  )
}

type ChipForScheduleProps = {
  label: ReactNode
  content?: ReactNode
}

export const ChipForScheduleDetails: FC<ChipForScheduleProps> = ({ label, content }) => (
  <Chip
    label={
      <div className="flex gap-x-2">
        <Typography>{label}</Typography>
        {content && <Typography  className="text-slate-500">{content}</Typography>}
      </div>
    }
    sx={{ margin: "4px 4px 4px 0" }}
  />
)
