import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { TailwindIcon } from "../types/tailwind"

type Colors = "gray" | "green" | "orange" | "white" | "red"

type Props = {
  Icon: TailwindIcon
  color?: Colors
  title?: string
  onClick?: () => void
  className?: string
  children?: ReactNode
  roundedLeft?: boolean
  roundedRight?: boolean
  disabled?: boolean
}

export const IconButton: FC<Props> = ({
  Icon,
  color = "gray",
  title = "",
  onClick = () => {},
  className,
  children,
  roundedLeft = true,
  roundedRight = true,
  disabled = false,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={disabled}
      className={clsx(
        "h-10 px-2.5 gap-x-2 flex justify-center items-center active:bg-gray-300 ease-in-out transition-colors",
        color === "gray" && "bg-gray-100 hover:bg-gray-200",
        color === "green" && "bg-green-600 hover:bg-green-700",
        color === "orange" && "bg-orange-600 hover:bg-orange-100",
        color === "white" && "bg-white hover:bg-gray-50",
        color === "red" && "bg-red-500 hover:bg-red-600",
        roundedLeft && "rounded-l",
        roundedRight && "rounded-r",
        disabled && "disabled:pointer-events-none"
      )}
      {...props}
    >
      <Icon
        className={clsx(
          "size-6",
          className,
          color === "green" && "text-white",
          color === "red" && "text-white",
          color === "orange" && "text-white"
        )}
      />
      {title && <p className="pr-1.5 font-medium whitespace-nowrap">{title}</p>}
      {children}
    </button>
  )
}
